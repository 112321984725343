import { LoaderFunctionArgs } from "react-router-dom";
import API, { ErrorWithDetails } from "../../utils/api";
import { PackageSchema, DetailedPackageFileSchema, DetailedPackageDeploymentSchema, PackageVersionSchema, PackageEndpointSchema } from "../../utils/types";

export interface PackageDetailsPageLoaderData {
  packageName: string,
  version: string,
  environment: string,
  pkg: PackageSchema,
  packageVersion: PackageVersionSchema,
  packageDeployment: DetailedPackageDeploymentSchema,
  filename: string | null,
  file: DetailedPackageFileSchema | null,
  fileEndpoint: PackageEndpointSchema | null,
  methods: string[]
};

export default function PackageDetailsPageLoader({ prefix } : { prefix?: string }) {
  return async ({ params, request } : LoaderFunctionArgs) => {

    const currentUrl = new URL(request.url);
    const method = currentUrl.searchParams.get('method');

    let { org, name, environment, '*': filename } = params;
    const packageName = (prefix ? `${prefix}/` : ``) + `${org}/${name}`;
    const version = (environment || '').startsWith('v-')
      ? environment
      : null;
    environment = (environment || '').startsWith('v-')
      ? "production"
      : environment;
    const [ packagesResponse, fileResponse ] = await Promise.all([
      API.get(
        'v1/packages/details',
        {name: packageName}
      ),
      (async () => {
        if (filename) {
          try {
            return await API.get(
              'v1/packages/files/details',
              {
                name: packageName,
                environment,
                version,
                filename
              }
            );
          } catch (e) {
            const error = e as ErrorWithDetails;
            if (error.status === 404) {
              return null;
            } else {
              throw e;
            }
          }
        }
      })()
    ]);
    const pkg = packagesResponse as PackageSchema;
    const file = (fileResponse || null) as DetailedPackageFileSchema | null;
    const packageVersion = (
      pkg?.packageVersions.find(v => v.environment === environment && v.version === version) ||
      pkg?.packageVersions.find(v => v.environment === 'production' && v.is_latest) ||
      pkg?.packageVersions.find(v => v.environment === 'staging' && v.is_latest) ||
      pkg?.packageVersions.find(v => v.environment === 'development' && v.is_latest)
    );
    const packageDeployment = (packageVersion?.packageDeployments[0] || null) as DetailedPackageDeploymentSchema | null;

    const fileEndpoints = packageDeployment?.packageEndpoints.filter(endpoint => endpoint.function_info.filename === filename) || [];
    const methods = fileEndpoints.map(endpoint => endpoint.function_info.method);
    const fileEndpoint = fileEndpoints.find(endpoint => endpoint.function_info.method === method) || fileEndpoints[0] || null;

    if (filename && !file && !fileEndpoints.length) {
      const e = new Error(`File ${filename} not found`) as ErrorWithDetails;
      e.status = 404;
      throw e;
    }
    return {
      packageName,
      version,
      environment,
      pkg,
      packageVersion,
      packageDeployment,
      filename,
      file,
      methods,
      fileEndpoint
    };
  };
};