import { createContext, useContext, useState } from "react";
import { Icon } from "react-feather";

// Define context type
interface BreadcrumbsContextType {
  breadcrumbs: Breadcrumb[];
  updateBreadcrumbs: (crumbs: Breadcrumb[]) => void;
}

export interface Breadcrumb {
  icon?: Icon;
  color?: string;
  label: string;
  path: string;
  stopCollapse?: boolean;
}

const BreadcrumbsContext = createContext<BreadcrumbsContextType>({
  breadcrumbs: [],
  updateBreadcrumbs: () => {}
});

export const useBreadcrumbs = () => useContext(BreadcrumbsContext);

export const BreadcrumbsProvider = ({ children }: { children: React.ReactNode }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const updateBreadcrumbs = (crumbs: Breadcrumb[] = []) => {
    setBreadcrumbs(crumbs);
  };
  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        updateBreadcrumbs,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};
