import {
  useState,
  forwardRef,
  useRef,
  useEffect,
  ForwardedRef,
  LegacyRef,
} from 'react';

import { useMobileDevice } from '../../utils/hooks';
import { Check, Edit, X, Icon, Send, ArrowUp } from 'react-feather';
import { Loader } from 'react-feather';

import './Textbox.scss';

const useForwardRef = <T,>(
  ref: ForwardedRef<T>,
  initialValue: any = null
) => {
  const targetRef = useRef<T>(initialValue);
  useEffect(() => {
    if (!ref) return;
    if (typeof ref === 'function') {
      ref(targetRef.current);
    } else {
      ref.current = targetRef.current;
    }
  }, [ref]);
  return targetRef;
};

type TextboxProps = {
  type?: "text" | "multiline" | "chat" | "email" | "password";
  clickToEdit?: boolean;
  heading?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  error?: string;
  lines?: number;
  maxLines?: number;
  loading?: boolean;
  startIcon?: Icon;
  endIcon?: Icon;
  autoFocus?: boolean;
  monospace?: boolean;
  size?: "small" | "medium";
  readonly?: boolean;
  disabled?: boolean;
  autoComplete?: boolean;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  onCancel?: () => void;
  onClear?: () => void;
  emptyChatOnSubmit?: boolean;
  tabIndex?: number;
};

const Textbox = forwardRef<HTMLTextAreaElement | HTMLInputElement, TextboxProps>(({
  type = "text",
  clickToEdit = false,
  heading,
  value,
  defaultValue,
  placeholder,
  error,
  lines = 1,
  maxLines = 1,
  loading = false,
  startIcon = null,
  endIcon = null,
  autoFocus = false,
  monospace = false,
  size = "medium",
  readonly = false,
  disabled = false,
  autoComplete = true,
  onChange = (value: string) => {},
  onSubmit = (value: string) => {},
  onCancel = () => {},
  onClear,
  emptyChatOnSubmit = true,
  tabIndex,
}, forwardedRef) => {

  const [isEditing, setIsEditing] = useState(false);
  const canEdit = clickToEdit && !isEditing;
  const isReadOnly = canEdit || readonly;

  const isMobileDevice = useMobileDevice();

  if (maxLines < lines) {
    maxLines = lines;
  }

  const StartIcon = startIcon;
  const EndIcon = endIcon;

  const inputRef = useForwardRef<HTMLTextAreaElement | HTMLInputElement>(forwardedRef);

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
      const value = inputRef.current?.value;
      inputRef.current?.setSelectionRange(value.length, value.length);
    } else {
      inputRef.current?.blur();
    }
  }, [isEditing]);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
  }, [autoFocus]);

  const handleClickToEdit = () => {
    if (clickToEdit) {
      setIsEditing(!isEditing);
    }
  };

  const handleDoubleClick = (e: any) => {
    e.preventDefault();
    if (clickToEdit) {
      handleClickToEdit();
    }
  };

  const handleBlur = () => {
    if (clickToEdit && isEditing) {
      setIsEditing(false);
      onSubmit(inputRef.current?.value || '');
    }
  };

  const handleInput = (event: any) => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    const value = (type === 'multiline' || type === 'chat')
      ? event.target.value
      : event.target.value.replaceAll('\n', '');
    input.value = value;
    adjustHeight();
  };

  const handlePaste = (event: any) => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    const paste = event.clipboardData.getData('text');
    const selStart = input.selectionStart !== null ? input.selectionStart : input.value.length;
    setTimeout(() => {
      input.selectionStart = selStart + paste.length;
      input.selectionEnd = input.selectionStart;
      adjustHeight();
      onChange(input.value);
    }, 0);
  };

  const handleKeydown = (event: any) => {
    const input = inputRef.current;
    if (!input) {
      return;
    }
    if (event.key === 'Escape') {
      if (clickToEdit && isEditing) {
        setIsEditing(false);
        onCancel();
      }
    } else if (event.key === 'Enter') {
      if (
        (type === 'chat' && !event.shiftKey && !isMobileDevice) ||
        (type !== 'multiline' && type !== 'chat') ||
        (type === 'multiline' && event.metaKey)
      ) {
        event.preventDefault();
        if (type === "chat") {
          if (input.value) {
            if (clickToEdit && isEditing) {
              setIsEditing(false);
            }
            onSubmit(input.value);
            if (emptyChatOnSubmit) {
              input.value = '';
              adjustHeight();
            }
          }
        } else {
          if (clickToEdit && isEditing) {
            setIsEditing(false);
          }
          onSubmit(input.value);
        }
      }
    }
  };

  const handleChatSend = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const input = inputRef.current;
    if (input?.value) {
      if (type === 'chat') {
        onSubmit(input?.value);
        if (input && emptyChatOnSubmit) {
          input.value = '';
          adjustHeight();
        }
      } else {
        onSubmit(input?.value);
      }
    }
  };

  const adjustHeight = () => {
    if ((type === 'multiline' || type === 'chat') && inputRef.current) {
      const el = inputRef.current as HTMLTextAreaElement;
      const computedStyle = window.getComputedStyle(el);
      const lineHeight = parseFloat(computedStyle.lineHeight);
      const paddingTop = parseFloat(computedStyle.paddingTop);
      const paddingBottom = parseFloat(computedStyle.paddingBottom);
      const borderTop = parseFloat(computedStyle.borderTop);
      const borderBottom = parseFloat(computedStyle.borderBottom);
      const minHeight = lines * lineHeight + paddingTop + paddingBottom;
      const maxHeight = maxLines * lineHeight + paddingTop + paddingBottom;
      
      // Reset height to auto to get the correct scrollHeight
      el.style.height = '0';
      
      // Set height based on content
      const newHeight = Math.max(minHeight, Math.min(maxHeight, el.scrollHeight)) + borderTop + borderBottom;
      el.style.height = `${newHeight}px`;
      
      window.requestAnimationFrame(() => {
        const remainder = el.scrollHeight - el.scrollTop - el.offsetHeight;
        if (remainder < lineHeight) {
          el.scrollTop += remainder;
        }
      });
    }
  };

  // Adjust height when window is resized
  useEffect(() => {
    const resizeHandler = () => {
      adjustHeight();
    };
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  useEffect(() => {
    adjustHeight();
  }, [value, lines, maxLines]);

  return (
    <div
      data-component="Textbox"
      data-type={type}
      data-size={size}
      data-click-to-edit={clickToEdit}
      data-is-editing={isEditing}
      className={error ? 'error' : ''}
    >
      {heading && <div className="heading">{heading}</div>}
      <div className="textbox-container">
        {StartIcon && (
          <div className="start-icon">
            <StartIcon />
          </div>
        )}
        <div className="end-icon">
          {loading && <Loader className="loading-spin" />}
          {!loading && onClear && value && <X className="clear-icon" onClick={onClear} />}
          {!loading && clickToEdit && (
            !isEditing
              ? <Edit className="edit-icon" onClick={handleClickToEdit} />
              : <Check className="edit-icon" onClick={handleClickToEdit} />
          )}
          {!loading && !clickToEdit && EndIcon && <EndIcon />}
          {type === 'chat' && (
            <div className="send-icon-container" onMouseDown={handleChatSend}>
              <ArrowUp className="send-icon" />
            </div>
          )}
        </div>
        {(
          (type === "multiline" || type === "chat")
            ? <textarea
                data-type={type}
                autoComplete={autoComplete ? 'on' : 'off'}
                autoCorrect={autoComplete ? 'on' : 'off'}
                autoCapitalize={autoComplete ? 'on' : 'off'}
                spellCheck={autoComplete ? true : false}
                inputMode={'text'}
                onDoubleClick={handleDoubleClick}
                onInput={handleInput}
                onPaste={handlePaste}
                onBlur={handleBlur}
                onChange={e => onChange(e.target.value || '')}
                onKeyDown={handleKeydown}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder}
                rows={1}
                className={monospace ? 'monospace' : ''}
                readOnly={isReadOnly}
                disabled={disabled}
                ref={inputRef as LegacyRef<HTMLTextAreaElement>}
                tabIndex={tabIndex}
              />
            : <input
                data-type={type}
                type={type}
                autoComplete={autoComplete ? 'on' : 'off'}
                autoCorrect={autoComplete ? 'on' : 'off'}
                autoCapitalize={autoComplete ? 'on' : 'off'}
                spellCheck={autoComplete ? true : false}
                onDoubleClick={handleDoubleClick}
                onInput={handleInput}
                onPaste={handlePaste}
                onBlur={handleBlur}
                onChange={e => onChange(e.target.value || '')}
                onKeyDown={handleKeydown}
                value={value}
                defaultValue={defaultValue}
                placeholder={placeholder}
                className={monospace ? 'monospace' : ''}
                readOnly={isReadOnly}
                disabled={disabled}
                ref={inputRef as LegacyRef<HTMLInputElement>}
                tabIndex={tabIndex}
              />
        )}
      </div>
      {error && <div className="error-text">{error}</div>}
    </div>
  );

});

export default Textbox;
