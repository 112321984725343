import { useState, useEffect } from "react";
import { useLoaderData, useRevalidator, useNavigate, useLocation } from "react-router-dom";

import { PackageSchema } from "../../utils/types";
import PackageCard from "./PackageCard";
import { PackagesPageLoaderData } from "./PackagePageLoader";

import API, { ErrorWithDetails } from "../../utils/api";

import "./PackagesPage.scss";
import { Info, Package, Coffee, Plus, Search, User } from "react-feather";
import { useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { Helmet } from "react-helmet-async";

import { EMPTY_PACKAGE } from "../dashboard/agents-chat/code/AgentsCode";
import { ContentButton, ContentButtonGroup } from "../../components/button/ContentButton";
import { InternalLink } from "../../components/internal-link/InternalLink";
import { useAuth } from "../../context/AuthContext";
import { CreatePackageModal } from './CreatePackageModal';
import { InfoBox } from "../../components/info/InfoBox";

export default function PackagesPage() {

  const { user, organization } = useAuth()
  const { organizationName, isDevelopment, packages } = useLoaderData() as PackagesPageLoaderData;
  const { breadcrumbs, updateBreadcrumbs } = useBreadcrumbs();
  const [showCreateModal, setShowCreateModal] = useState(false);

  let displayTitle = 'Packages';
  if (organizationName) {
    displayTitle = `${organizationName} | Packages`;
  } else if (isDevelopment) {
    displayTitle = 'Development packages';
  }
  const description = `Explore packages created by ${organizationName}`;

  const createPackage = async (name: string, environment: string) => {
    const saveFiles = EMPTY_PACKAGE;
    const base64Files: {[key: string]: string} = {};
    for (const pathname in saveFiles) {
      base64Files[pathname] = saveFiles[pathname].value._base64;
    }
    const params = {
      name,
      environment,
      base64Files,
      publishError: false,
      autoInstall: true,
      _stream: {
        log: ({ event, data }: { event: string, data: any }) => {
          // Push deploy logs to console
          console.log(data);
        }
      }
    } as {[key: string]: any};
    const result = await API.post(
      'v1/packages',
      params
    );
    return result;
  }

  useEffect(() => {
    if (organizationName) {
      const breadcrumbs = [
        {
          icon: User,
          label: `@${organizationName}`,
          path: `/packages/@${organizationName}`
        }
      ];
      if (!user) {
        breadcrumbs.unshift({
          icon: Package,
          label: 'Packages',
          path: '/packages'
        });
      }
      updateBreadcrumbs(breadcrumbs);
    } else if (isDevelopment) {
      const breadcrumbs = [
        {
          icon: Coffee,
          label: 'Development',
          path: '/packages/development'
        }
      ];
      if (!user) {
        breadcrumbs.unshift({
          icon: Package,
          label: 'Packages',
          path: '/packages'
        });
      }
      updateBreadcrumbs(breadcrumbs);
    } else {
      updateBreadcrumbs([]);
    }
    return () => {
      updateBreadcrumbs([]);
    }
  }, [organizationName, isDevelopment]);

  return (
    <div data-component="PackagesPage">
      <Helmet>
        <title>{displayTitle} | Funct</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className="packages-title">
        <div className="heading">
          {!organizationName && !isDevelopment && 'Package registry'}
          {isDevelopment && 'Development packages'}
          {!!organizationName && `Packages by @${organizationName}`}
        </div>
        <div className="subheading">
          {!organizationName && !isDevelopment && 'Packages extend the capabilities of your agent.'}
          {isDevelopment && 'Packages in development and staging environments.'}
          {!!organizationName && `All packages you have access to published by this user.`}
        </div>
      </div>
      <div className="packages-actions">
        <div className="packages-actions-row">
          <ContentButtonGroup>
            <ContentButton
              icon={Search}
              active={!organizationName && !isDevelopment}
              color="grey"
              to="/packages"
            >
              Discover
            </ContentButton>
            <ContentButton
              icon={Coffee}
              active={isDevelopment}
              color="grey"
              to="/packages/development"
            >
              Development
            </ContentButton>
            {user && (
              <ContentButton
                icon={User}
                active={organizationName === organization?.name}
                color="grey"
                to={`/packages/@${organization.name}`}
              >
                @{organization.name}
              </ContentButton>
            )}
          </ContentButtonGroup>
          <div className="spacer" />
          {user && (
            <ContentButton
              color="blue"
              icon={Plus}
              onClick={() => setShowCreateModal(true)}
            >
              Create package
            </ContentButton>
          )}
        </div>
      </div>
      <div className="packages-list">
        {!packages.length && (
          <div className="packages-list-empty">
            No packages found.
          </div>
        )}
        {packages.map(pkg => {
          return (
            <PackageCard
              key={pkg.display_name}
              pkg={pkg}
            />
          );
        })}
      </div>
      {showCreateModal && (
        <CreatePackageModal
          onClose={() => setShowCreateModal(false)}
          createPackage={createPackage}
        />
      )}
    </div>
  );

};