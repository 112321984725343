import { useEffect, useRef } from "react";
import { ContentButton, ContentButtonGroup } from "../button/ContentButton";
import { Copy, Icon } from 'react-feather';
import { toast } from "../../services/toast";

import "./MiniEditor.scss";
import "./EditorStyleOverride.scss";

interface MiniEditorOption {
  name: string,
  language: string,
  value: string | { value: string | { _base64: string }, type: string };
};

export function MiniEditor({
  icon,
  label = '',
  copyable = false,
  language = 'javascript',
  value = '',
  display = 'default',
  type = 'default',
  options,
  selectedOptionName,
  onSelectOption = () => {},
  showLines = true,
  readonly = false,
  maximized = false,
  errorPos = null,
  onRun,
  onSave,
  onChange
} : {
  icon?: Icon;
  label?: string;
  copyable?: boolean;
  language?: string;
  value?: string | { value: string | { _base64: string }, type: string };
  display?: 'default' | 'package' | 'embed';
  type?: 'default' | 'error' | 'success';
  options?: MiniEditorOption[];
  selectedOptionName?: string;
  onSelectOption?: (option: MiniEditorOption) => void;
  showLines?: boolean;
  readonly?: boolean;
  maximized?: boolean;
  errorPos?: { lineIndex: number, column: number } | null;
  onRun?: () => void;
  onSave?: () => void;
  onChange?: (value: string) => void;
}) {

  const editorRef = useRef<HTMLDivElement>(null);
  const cphRef = useRef<any>(null);
  const showHeader = !!(icon || label || copyable);
  const StartIcon = icon;

  if (!options || options.length === 0) {
    options = [{
      name: label,
      language: language,
      value: value
    }];
  };

  const selectedOption = options.find(option => option.name === selectedOptionName) || options[0];

  useEffect(() => {
    if (!cphRef.current) {
      const editor = new window.Copenhagen.Editor({
        language: language,
        value: value,
        nolines: !showLines,
        readonly: readonly,
        maximized: maximized
      });
      cphRef.current = editor;
      if (editorRef.current && cphRef.current) {
        const editorEl = editorRef.current as HTMLDivElement;
        const editor = cphRef.current;
        editor.open(editorEl, false);
        editor.fileTabs.hide();
        editor.treeView.hide();
      }
    }
    return () => {
      cphRef.current?.close();
      cphRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (cphRef.current) {
      const editor = cphRef.current;
      if (errorPos) {
        editor.setError(errorPos.lineIndex, errorPos.column);
      } else {
        editor.setError(null);
      }
    }
  }, [errorPos]);

  useEffect(() => {
    if (cphRef.current) {
      const editor = cphRef.current;
      editor.off('change');
      editor.on('change', (editor: any, text: string, cursors: any[]) => {
        onChange?.(text);
      });
    }
  }, [onChange]);

  // Only change value if it's different from the current value
  // Otherwise we keep resetting the value completely
  useEffect(() => {
    if (cphRef.current) {
      cphRef.current.setReadOnly(readonly);
      const currentValue = cphRef.current.getValue();
      if (currentValue !== selectedOption.value) {
        cphRef.current.setValue(selectedOption.value);
      }
    }
  }, [selectedOption?.value]);
    
  useEffect(() => {
    if (cphRef.current) {
      cphRef.current.setReadOnly(readonly);
      cphRef.current.setLanguage(language);
      cphRef.current.setMaximized(maximized);
      cphRef.current.setNoLines(!showLines);
      cphRef.current.render(cphRef.current.value, true);
      if (onRun) {
        cphRef.current.addHotkey('ctrl+enter', () => onRun());
      } else {
        cphRef.current.addHotkey('ctrl+enter', () => {});
      }
      if (onSave) {
        cphRef.current.addHotkey('ctrl+s', () => onSave());
      } else {
        cphRef.current.addHotkey('ctrl+s', () => {});
      }
    }
  }, [readonly, language, maximized, showLines, onRun, onSave]);

  return (
    <div data-component="MiniEditor" data-type={type} data-display={display}>
      {showHeader && (
        <div className="mini-editor-header">
          {StartIcon && <StartIcon className="start-icon"/>}
          <span>{label}</span>
          <div className="spacer" />
          <div className="actions">
            {options.length > 1 && (
              <ContentButtonGroup>
                {options.map((option, index) => {
                  const selected = option.name === selectedOption.name;
                  return (
                    <ContentButton
                      key={option.name}
                      size={display === 'package' ? 'default' : 'small'}
                      color={display === 'package' ? 'grey' : 'default'}
                      onClick={() => onSelectOption(option)}
                      active={selected}
                    >
                      {option.name}
                    </ContentButton>
                  );
                })}
              </ContentButtonGroup>
            )}
            {typeof selectedOption.value === 'string' && copyable && (
              <ContentButton
                size={display === 'package' ? 'default' : 'small'}
                color={display === 'package' ? 'grey' : 'default'}
                icon={Copy}
                onClick={() => {
                  navigator.clipboard.writeText(selectedOption.value as string);
                  toast.message({ type: 'success', message: 'Copied to clipboard' });
                }}
              />
            )}
          </div>
        </div>
      )}
      <div className="mini-editor-content" ref={editorRef}></div>
    </div>
  );
}