import { useState, useRef, useEffect } from "react";
import { X } from "react-feather";
import Button from "../button/Button";
import "./AlertModal.scss";
import Textbox from "../textbox/Textbox";

interface AlertModalProps {
  message?: string;
  color?: "red" | "green" | "blue";
  type?: "confirm" | "text" | "children";
  autoFocus?: boolean;
  confirmLabel?: string;
  canConfirm?: boolean;
  error?: string | null;
  onClose?: () => void;
  onConfirm?: (complete: Function, value?: any) => void;
  onCancel?: () => void;
  children?: React.ReactNode;
}

export function AlertModal({
  message = "Continue?",
  color = "red",
  type = "confirm",
  autoFocus = false,
  confirmLabel = "Okay",
  canConfirm = true,
  error,
  onClose = () => {},
  onConfirm = void 0,
  onCancel = void 0,
  children
}: AlertModalProps) {

  if (type === "children" && !children) {
    throw new Error("children type requires children prop");
  } else if (type !== "children" && children) {
    throw new Error("can only set children prop if type is children");
  }

  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const okayButtonRef = useRef<HTMLButtonElement>(null);
  const complete = () => setIsLoading(false);

  const handleBackgroundClick = (e: any) => {
    if (!isLoading) {
      onCancel && onCancel();
      onClose();
    }
  };

  const handleModalClick = (e: any) => {
    e.stopPropagation();
  };

  const handleCancelClick = () => {
    if (!isLoading) {
      onCancel && onCancel();
      onClose();
    }
  };

  const handleConfirmClick = () => {
    if (!isLoading) {
      setIsLoading(true);
      onConfirm?.(complete, type === "text" ? value : void 0);
    }
  };

  return (
    <div data-component="AlertModal" onMouseDown={handleBackgroundClick}>
      <div className="modal" onMouseDown={handleModalClick}>
        {children && (
          <div className="message">{children}</div>
        )}
        {!children && (
          <>
            <div className="message">
              {message}
              {children}
            </div>
            {type === "text" && (
              <Textbox
                autoFocus={autoFocus}
                onChange={(value) => setValue(value)}
              />
            )}
          </>
        )}
        {error && <div className="error-text">{error}</div>}
        <div className="actions">
          {onCancel && (
            <Button
              icon={X}
              label="Cancel"
              size="small"
              disabled={isLoading}
              onClick={handleCancelClick}
              autoFocus={(type !== 'text' && type !== 'children') && autoFocus}
            />
          )}
          {onConfirm && (
            <Button
              ref={okayButtonRef}
              label={confirmLabel}
              size="small"
              color={color}
              loading={isLoading}
              disabled={type === "text" ? !value.trim() : !canConfirm}
              onClick={handleConfirmClick}
              autoFocus={type !== 'text' && type !== 'children' && !onCancel && autoFocus}
            />
          )}
        </div>
      </div>
    </div>
  );

}