import { useNavigate } from "react-router-dom";
import { PackageSchema, PackageVersionSchema } from "../../utils/types";
import { formatRecent } from "../../utils/time";
import { InternalLink } from "../../components/internal-link/InternalLink";
import "./PackageCard.scss"
import { Activity, Check, Code, Edit, Lock, Package, Star, Cpu, Plus, Delete, Trash } from "react-feather";
import { ContentButton } from "../../components/button/ContentButton";
import { useAuth } from "../../context/AuthContext";
import Select from "../../components/select/Select";
import { useState } from "react";
import API from "../../utils/api";
import { AlertModal } from "../../components/modals/AlertModal";
import Textbox from "../../components/textbox/Textbox";
import { toast } from "../../services/toast";
import ImageUploader from "../../components/uploader/ImageUploader";
import { useMobileDevice } from "../../utils/hooks";

export default function PackageCard({
  pkg,
  environment,
  version = null,
  mode = 'card',
  installed = false,
  onEdit = () => {},
  onInstall = () => {},
  onUninstall = () => {}
} : {
  pkg: PackageSchema,
  environment?: string,
  version?: string | null,
  mode?: 'card' | 'page' | 'install',
  installed?: boolean,
  onEdit?: (pkg: PackageSchema) => void,
  onInstall?: (pkg: PackageSchema, version?: PackageVersionSchema) => void,
  onUninstall?: (pkg: PackageSchema, version?: PackageVersionSchema) => void
}) {

  const [ viewPkg, setViewPkg ] = useState(pkg);
  const [ title, setTitle ] = useState(viewPkg.display_title);
  const [ description, setDescription ] = useState(viewPkg.description);
  const [ isEditingTitle, setIsEditingTitle ] = useState(false);
  const [ isEditingDescription, setIsEditingDescription ] = useState(false);
  const [ isUploadingImage, setIsUploadingImage ] = useState(false);

  const { user, organization } = useAuth();
  const navigate = useNavigate();
  const isMobileDevice = useMobileDevice();

  const canEdit = organization?.name === viewPkg.organization.name && !viewPkg.agent && mode === 'page';
  const canEditCode = organization?.name === viewPkg.organization?.name && mode !== 'install';

  const packageVersion = (
    pkg?.packageVersions.find(v => v.environment === environment && v.version === version) ||
    pkg?.packageVersions.find(v => v.environment === 'production' && v.is_latest) ||
    pkg?.packageVersions.find(v => v.environment === 'staging' && v.is_latest) ||
    pkg?.packageVersions.find(v => v.environment === 'development' && v.is_latest)
  );

  version = packageVersion?.version || packageVersion?.environment || null;
  const [ selectedVersion, setSelectedVersion ] = useState(version);

  const versions = pkg?.packageVersions.map(v => v.version || v.environment);
  const versionsOptions = versions.map(v => ({
    label: v,
    value: v
  }));

  const shortTitle = viewPkg.agent
    ? viewPkg.agent.agentConfigs?.[0]?.name.split('/').slice(-1)[0]
    : title;
  const avatarCharacters = shortTitle.split(/\W/).slice(0, 2).map(c => c[0]).join('').toUpperCase();

  const colors = ['purple', 'blue', 'green', 'orange', 'red'];
  const packageColor = colors[new Date(viewPkg.created_at).getTime() % colors.length]

  const packageType = viewPkg.display_name.startsWith('@')
    ? 'public'
    : viewPkg.display_name.split('/')[0];
  const packageTypeLabel = packageType[0].toUpperCase() + packageType.slice(1);

  const codeUrl = viewPkg.agent
    ? `/chat/${viewPkg.agent.unique_id}?tab=code&fullscreen=t`
    : `/code/${viewPkg.display_name}/${version}?&fullscreen=t`;

  const updatePackage = async (thumbnailImage: { _base64: string } | false | null = null) => {
    setIsEditingTitle(false);
    setIsEditingDescription(false);
    if (thumbnailImage) {
      setIsUploadingImage(true);
    }
    try {
      const result = await API.put(`v1/packages/`, {
        name: viewPkg.display_name,
        title: title,
        description: description,
        thumbnail_image: thumbnailImage
      });
      const updatedPkg = result as PackageSchema;
      const newViewPkg = JSON.parse(JSON.stringify(pkg));
      newViewPkg.display_title = updatedPkg.display_title;
      newViewPkg.description = updatedPkg.description;
      newViewPkg.thumbnail_image_url = updatedPkg.thumbnail_image_url;
      setTitle(newViewPkg.display_title);
      setDescription(newViewPkg.description);
      setViewPkg(newViewPkg);
      onEdit?.(newViewPkg);
      toast.message({ type: 'success', message: `Package information updated!`, duration: 1000 });
    } catch (e) {
      const error = e as Error;
      toast.message({ type: 'error', message: error.message, duration: 5000 });
    }
    setIsUploadingImage(false);
  }

  return (
    <div data-component="PackageCard"
      data-mode={mode}
      data-is-mobile-device={isMobileDevice}
      data-environment={packageVersion?.environment}
    >
      <div className="package-card-header">
        <div className="package-card-rating" style={{display: 'none'}}>
          <Star className="star"/>
          <span>4.5</span>
        </div>
        <div className="vertical-divider" style={{display: 'none'}} />
        <div className="package-card-activity" style={{display: 'none'}}>
          <Activity />
          <span>100</span>
        </div>
        <div className="vertical-divider" style={{display: 'none'}} />
        <div className="package-card-type">
          <span>
            <div className="package-card-icon">
              {viewPkg.agent && <Cpu />}
              {viewPkg.is_private && <Lock />}
              {!(viewPkg.agent || viewPkg.is_private) && <Package />}
            </div>
            <div className="package-card-type-name">
              {packageTypeLabel}
            </div>
          </span>
        </div>
        {mode === 'install' && installed && (
          <div className="package-card-install-status">
            <Check />
            <span>
              Installed
            </span>
          </div>
        )}
      </div>
      <div className="package-avatar">
        <div className="package-avatar-content" data-color={viewPkg.thumbnail_image_url ? '' : packageColor}>
          {!viewPkg.thumbnail_image_url && (
            <div className="package-avatar-placeholder">
              {avatarCharacters}
            </div>
          )}
          {!canEdit &&viewPkg.thumbnail_image_url && (
            <img
              src={viewPkg.thumbnail_image_url}
              alt={viewPkg.display_title}
            />
          )}
          {canEdit && (
            <ImageUploader
              type="inline"
              loading={isUploadingImage}
              image={viewPkg.thumbnail_image_url || void 0}
            onChange={(file) => {
              const thumbnailImage = file ? {_base64: file} : false;
              updatePackage(thumbnailImage);
              }}
            />
          )}
        </div>
        <div className="package-card-title">
          <div className="package-card-display-name">
            {!isEditingTitle && (
              <>
                <InternalLink to={`/packages/${viewPkg.display_name}`}>
                  {shortTitle}
                </InternalLink>
                {canEdit && (
                  <ContentButton
                    color="grey"
                    size="small"
                    icon={Edit}
                    onClick={() => {
                      setIsEditingTitle(true);
                    }}
                  />
                )}
              </>
            )}
            {isEditingTitle && (
              <div className="package-card-input">
                <Textbox
                  size="small"
                  value={title}
                  autoFocus={true}
                  onChange={(value) => setTitle(value)}
                  onSubmit={() => updatePackage()}
                />
                <ContentButton
                  color="grey"
                  icon={Check}
                  onClick={() => updatePackage()}
                />
              </div>
            )}
          </div>
          <div className="package-card-description">
            {viewPkg.agent && (
              <span>
                Custom code package for agent
              </span>
            )}
            {!viewPkg.agent && (
              <>
                {!isEditingDescription && (
                  <>
                    <span>{description}</span>
                    {canEdit && (
                      <ContentButton
                        color="grey"
                        size="small"
                        icon={Edit}
                        onClick={() => setIsEditingDescription(true)}
                      />
                    )}
                  </>
                )}
                {isEditingDescription && (
                  <div className="package-card-input">
                    <Textbox
                      size="small"
                      value={description}
                      autoFocus={true}
                      onChange={(value) => setDescription(value)}
                      onSubmit={() => updatePackage()}
                    />
                    <ContentButton
                      color="grey"
                      icon={Check}
                      onClick={() => updatePackage()}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="package-card-body">
        <div className="package-card-footer">
          <div className="package-card-info">
            <div className="package-card-necessary">
              <div className="package-card-name">
                {viewPkg.display_name}
              </div>
              <span>&middot;</span>
              <div className="package-card-name" data-version={version}>
                {version}
              </div>
              <span>&middot;</span>
              <div className="package-card-name">
                {formatRecent(packageVersion?.updated_at || viewPkg.created_at)}
                &nbsp;by&nbsp;
                <InternalLink to={`/packages/@${viewPkg.organization.name}`}>
                  @{viewPkg.organization.name}
                </InternalLink>
              </div>
            </div>
          </div>
          <div className="spacer" />
          <div className="package-card-actions">
            <Select
              readonly={false}
              size="small"
              options={versionsOptions}
              value={selectedVersion}
              onChange={(value) => {
                if (mode !== 'install') {
                  navigate(`/packages/${viewPkg.display_name}/${value}`);
                } else {
                  setSelectedVersion(value);
                }
              }}
            />
            {mode === 'install' && !installed && (
              <ContentButton
                color="green"
                icon={Plus}
                onClick={() => {
                  const packageVersion = viewPkg.packageVersions.find(v => v.environment === selectedVersion || v.version === selectedVersion);
                  onInstall?.(viewPkg, packageVersion);
                }}
              >
                Install
              </ContentButton>
            )}
            {mode === 'install' && installed && (
              <ContentButton
                darken={true}
                icon={Trash}
                onClick={() => {
                  const packageVersion = viewPkg.packageVersions.find(v => v.environment === selectedVersion || v.version === selectedVersion);
                  onUninstall?.(viewPkg, packageVersion);
                }}
              >
                Uninstall
              </ContentButton>
            )}
            {canEditCode && (
              <ContentButton
                darken={mode === 'page' ? false : true}
                color={mode === 'page' ? 'grey' : 'default'}
                icon={Code}
                to={codeUrl}
              >
                Edit code
              </ContentButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}