import { UserSchema } from './types';

export type ErrorWithDetails = Error & {
  status?: number,
  pathname?: string,
  details?: {[key: string]: any},
  stack?: string,
  rawError?: {
    type?: string,
    message?: string,
    stack?: string | string[],
    details?: {[key: string]: any},
  },
  clear?: (key: string) => ErrorWithDetails | null
};

/**
 * ASCII to Unicode (decode Base64 to original data)
 */
export const atou = (b64: string) => {
  return decodeURIComponent(Array.prototype.map.call(atob(b64), function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

/**
 * Unicode to ASCII (encode data to Base64)
 */
export const utoa = (data: string) => {
  return btoa(encodeURIComponent(data).replace(/%([0-9A-F]{2})/g, function (match, p1) {
    return String.fromCharCode(parseInt(p1, 16));
  }));
}

export function arrayBufferToBase64 (buffer: ArrayBuffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export function arrayBufferToText(buffer: ArrayBuffer) {
  const decoder = new TextDecoder();
  return decoder.decode(buffer);
}

class APIRequester {

  root: string | null;
  apiKey: string | null;
  user: UserSchema | null;
  userCallback: ((user: UserSchema | null) => void) | null;

  constructor(root: string) {
    this.root = root;
    this.apiKey = this.getLocalStorage('apiKey');
    this.user = this.getLocalStorage('user');
    this.userCallback = null;
  }

  setUserCallback (userCallback: (user: UserSchema | null) => void | null) {
    this.userCallback = userCallback;
  }

  setLocalStorage (key: string, value: any = null) {
    if (value === null) {
      localStorage.removeItem(`${this.root}:${key}`);
    } else {
      localStorage.setItem(`${this.root}:${key}`, utoa(JSON.stringify(value)));
    }
    return value;
  }

  getLocalStorage (key: string) {
    let json = null;
    try {
      const item = localStorage.getItem(`${this.root}:${key}`);
      if (item !== null) {
        json = JSON.parse(atou(item));
      }
    } catch (e) {
      const error = e as Error;
      console.error(
        `Could not load "${key}" from localStorage, invalid JSON\n`,
        error.message
      );
    }
    return json;
  }

  authenticate(apiKey: string) {
    this.apiKey = apiKey;
    this.setLocalStorage('apiKey', this.apiKey);
  }

  async login({
    username,
    password
  } : {   
    username: string,
    password: string
  }) {
    const params = {
      username,
      password,
      grant_type: 'password'
    };
    const loginResult = await this.post('auth', params);
    const key = loginResult?.accessTokens?.[0]?.key as string;
    if (!key) {
      throw new Error(`No key returned, unable to login`);
    }
    this.authenticate(key);
    return this.refreshUser();
  }

  async login3p({
    source,
    token
  } : {   
    source: "discord",
    token: string
  }) {
    const params = {token};
    const loginResult = await this.post(`auth/third_party/${source}`, params);
    const key = loginResult?.user.accessTokens?.[0]?.key as string;
    if (!key) {
      throw new Error(`No key returned, unable to login`);
    }
    this.authenticate(key);
    await this.refreshUser();
    return loginResult;
  }

  async refreshUser() {
    try {
      const [ userResult, subscriptionResult ] = await Promise.all([
        this.get('users/me'),
        this.get('payments/subscriptions')
      ]);
      this.user = userResult as UserSchema | null;
      if (this.user && subscriptionResult && subscriptionResult.currentPlan) {
        this.user.currentPlan = subscriptionResult.currentPlan;
      }
      this.setLocalStorage('user', this.user);
      this.userCallback && this.userCallback(this.user);
      return this.user;
    } catch (e) {
      const error = e as ErrorWithDetails;
      console.error(`Error fetching user: ${error.message}`);
    }
  }

  async logout() {
    try {
      await this.post('auth/logout');
      this.user = this.setLocalStorage('user', null);
      this.apiKey = this.setLocalStorage('apiKey', null);
      this.userCallback && this.userCallback(this.user);
    } catch (e) {
      console.error(e);
    }
  }

  async request(
    pathname: string,
    method: string,
    {
      requestHeaders = {},
      queryParams = {},
      params = {},
      jsonMode = false
    } : {
      requestHeaders?: {[key: string]: any},
      queryParams?: {[key: string]: any},
      params?: {[key: string]: any},
      jsonMode?: boolean
    }
  ): Promise<{
    text: string,
    json: {[key: string]: any} | null,
    fileData: { value: { _base64: string }, type: string } | null
  }> {
    const isExternal = !!pathname.match(/^.*:\/\//);
    if (!isExternal) {
      if (pathname.startsWith('/')) {
        pathname = pathname.slice(1);
      }
      if (!pathname.endsWith('/')) {
        pathname = pathname + '/';
      }
      pathname = `${this.root}/${pathname}`;
    }
    const headers = {...requestHeaders};
    const _stream = queryParams._stream || params._stream;
    const _debug = queryParams._debug || params._debug;
    if (queryParams.hasOwnProperty('_stream')) {
      queryParams._stream = true;
    }
    if (queryParams.hasOwnProperty('_debug')) {
      queryParams._debug = true;
    }
    if (params.hasOwnProperty('_stream')) {
      params._stream = true;
    }
    if (params.hasOwnProperty('_debug')) {
      params._debug = true;
    }
    headers['Content-Type'] = 'application/json';
    if (this.apiKey && !isExternal) {
      headers['Authorization'] = `Bearer ${this.apiKey}`;
    }
    const query: {[key: string]: any} = {};
    for (const key in queryParams) {
      if (queryParams[key] !== null && queryParams[key] !== void 0) {
        query[key] = queryParams[key];
      }
    }
    const querystring = Object.keys(query).map(key => {
      let value = query[key];
      if (typeof value === 'object') {
        value = JSON.stringify(value);
      }
      return [encodeURIComponent(key), encodeURIComponent(value)].join('=');
    }).join('&');
    const url = `${pathname}?${querystring}`;
    const body = Object.keys(params).length
      ? JSON.stringify(params)
      : ''
    if (body && (method === 'GET' || method === 'DELETE')) {
      throw new Error(`Can not send request body with GET or DELETE (${pathname})`);
    }
    const options: {[key: string]: any} = {
      method,
      headers
    };
    if (body) {
      options.body = body;
    }
    const response = await fetch(url, options);
    let text = '';
    let json = null;
    let fileData = null;
    const isStreaming = response.headers.get('content-type')?.split(';')[0] === 'text/event-stream';
    let statusCode = 0;
    if (isStreaming) {
      const messages = [];
      const events: {[key: string]: any } = {};
      const reader = response.body?.getReader();
      if (reader) {
        let textAggregator = '';
        while (true) {
          const { done, value } = await reader?.read();
          if (done) break;
          const newText = new TextDecoder().decode(value);
          text += newText;
          for (let i = 0; i < newText.length; i++) {
            const char = newText[i];
            textAggregator += char;
            if (textAggregator.slice(-2) === '\n\n') {
              const entry = textAggregator.split('\n\n').shift() || '';
              const lines = entry.split('\n');
              const event: {[key: string]: any} = lines.reduce((acc, line) => {
                const key = line.split(': ')[0];
                const value = line.split(': ').slice(1).join(': ') || '""';
                acc[key] = value;
                return acc;
              }, {} as {[key: string]: any});
              try {
                event.data = JSON.parse(event.data);
              } catch (e) {
                console.error(`Invalid JSON from streaming response: ${event.data}`);
              }
              events[event.event] = events[event.event] || [];
              events[event.event].push(event);
              if (typeof _stream?.[event.event] === 'function') {
                _stream[event.event](event as {id?: string, event: string, data: any});
              }
              if (typeof _stream?.['*'] === 'function') {
                _stream['*'](event as {id?: string, event: string, data: any});
              }
              if (typeof _debug?.[event.event] === 'function') {
                _debug[event.event](event as {id?: string, event: string, data: any});
              }
              if (typeof _debug?.['*'] === 'function') {
                _debug['*'](event as {id?: string, event: string, data: any});
              }
              messages.push(event);
              textAggregator = '';
            }
          }
        }
      }
      if (events['@response']) {
        const streamResponse = events['@response'][0].data;
        statusCode = streamResponse.statusCode;
        const headers = Object.keys(streamResponse.headers || {}).reduce((headers, key) => {
          headers[key.toLowerCase()] = streamResponse.headers[key];
          return headers;
        }, {} as {[key: string]: string});
        if (jsonMode || headers['content-type']?.split(';')[0] === 'application/json') {
          try {
            json = JSON.parse(streamResponse.body);
          } catch (e) {
            throw new Error(`Invalid JSON (${pathname}): ${(e as Error).message}`);
          }
          text = JSON.stringify(json);
        } else {
          text = streamResponse.body;
          try {
            const bufjson = JSON.parse(text);
            if (!bufjson.hasOwnProperty('_base64')) {
              throw new Error(`Invalid buffer object`);
            }
            text = window.atob(bufjson._base64);
            fileData = {
              value: bufjson,
              type: headers['content-type'] || 'application/octet-stream'
            };
          } catch (e) {
            // Do nothing...
          }
        }
      }
    } else if (jsonMode || response.headers.get('content-type')?.split(';')[0] === 'application/json') {
      try {
        json = await response.json();
      } catch (e) {
        throw new Error(`Invalid JSON (${pathname}): ${(e as Error).message}`);
      }
      text = JSON.stringify(json);
    } else {
      const arrayBuffer = await response.arrayBuffer();
      text = arrayBufferToText(arrayBuffer);
      fileData = {
        value: { _base64: arrayBufferToBase64(arrayBuffer) },
        type: response.headers.get('content-type') || 'application/octet-stream'
      };
    }
    statusCode = statusCode || response.status;
    if (statusCode !== 200) {
      if (statusCode === 401 && !isExternal) {
        // If the request is internal, this means the user is not authenticated
        // We should log them out...
        this.user = this.setLocalStorage('user', null);
        this.apiKey = this.setLocalStorage('apiKey', null);
        this.userCallback && this.userCallback(this.user);
      }
      const error = new Error(json?.error?.message || text) as ErrorWithDetails;
      error.status = statusCode;
      error.pathname = pathname;
      if (json.error) {
        error.rawError = json.error;
        if (typeof error.rawError?.stack === 'string') {
          const stack = error.rawError.stack;
          error.stack = stack;;
          error.rawError.stack = stack.split('\n');
        }
        if (json.error.details) {
          error.details = json.error.details;
          error.clear = function (key: string) {
            const cError = new Error(this.message) as ErrorWithDetails;
            cError.pathname = pathname;
            cError.details = JSON.parse(JSON.stringify(this.details));
            cError.clear = this.clear;
            if (cError.details && cError.details[key]) {
              delete cError.details[key];
            }
            if (Object.keys(cError.details || {}).length) {
              return cError;
            } else {
              return null;
            }
          };
        }
      }
      throw error;
    }
    return {
      text,
      json,
      fileData
    };
  }

  async get(
    pathname: string,
    queryParams: {[key: string]: any} = {},
    requestHeaders: {[key: string]: any} = {}
  ) {
    const result = await this.request(pathname, 'GET', {queryParams, requestHeaders, jsonMode: true});
    return result.json;
  }

  async del(
    pathname: string,
    queryParams: {[key: string]: any} = {},
    requestHeaders: {[key: string]: any} = {}
  ) {
    const result = await this.request(pathname, 'DELETE', {queryParams, requestHeaders, jsonMode: true});
    return result.json;
  }

  async post(
    pathname: string,
    params: {[key: string]: any} = {},
    requestHeaders: {[key: string]: any} = {}
  ) {
    const result = await this.request(pathname, 'POST', {params, requestHeaders, jsonMode: true});
    return result.json;
  }

  async put(
    pathname: string,
    params: {[key: string]: any} = {},
    requestHeaders: {[key: string]: any} = {}
  ) {
    const result = await this.request(pathname, 'PUT', {params, requestHeaders});
    return result.json;
  }

}

const API = new APIRequester(process.env.REACT_APP_API_URL || '');

export default API;