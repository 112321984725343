import { useEffect, useState } from "react";
import { useLoaderData, useSearchParams } from "react-router-dom";
import { useAuth } from "../../../../context/AuthContext";
import { BillingSubscriptionPageLoaderData } from "./BillingSubscriptionPageLoader";
import { PricingCard } from "../../../pricing/PricingCard";
import { X } from "react-feather";
import API from "../../../../utils/api";
import { AlertModal } from "../../../../components/modals/AlertModal";

import "./BillingSubscriptionPage.scss";
import Button from "../../../../components/button/Button";

export default function BillingSubscriptionPage() {

  const [isLoading, setIsLoading] = useState(false);
  const [showCancelAlert, setShowCancelAlert] = useState(false);

  const { user } = useAuth();
  const { plans } = useLoaderData() as BillingSubscriptionPageLoaderData;
  const [ searchParams, setSearchParams ] = useSearchParams();

  const planName = user?.currentPlan?.name || plans[0].name;

  const activePlan = plans.find(plan => plan.name === planName);
  const downgradePlans = plans
    .filter(plan => (plan.price?.usd || 0) < (activePlan?.price?.usd || 0))
    .reverse();
  const upgradePlans = plans.filter(plan => (plan.price?.usd || 0) > (activePlan?.price?.usd || 0));

  const cancelSubscription = async () => {
    setIsLoading(true);
    try {
      await API.del('payments/subscriptions');
      await API.refreshUser();
      setIsLoading(false);
    } catch (e) {
      const error = e as Error;
      setIsLoading(false);
      alert(error.message);
    }
  };

  // Always fetch the user on this page
  useEffect(() => {
    API.refreshUser();
  }, []);

  return (
    <div data-component="BillingSubscriptionPage">
      {showCancelAlert && (
        <AlertModal
          message="Are you sure you wish to cancel? This will take effect immediately. If you are over your limits your agents will have reduced functionality."
          onClose={() => setShowCancelAlert(false)}
          onConfirm={async (complete) => {
            await cancelSubscription();
            complete();
            setShowCancelAlert(false);
          }}
          onCancel={() => setShowCancelAlert(false)}
        />
      )}
      {activePlan && (
        <div className="active-plan">
          <PricingCard plan={activePlan} key={activePlan.name} horizontal />
        </div>
      )}
      <div className="pricing-plans">
        {upgradePlans.map(plan => <PricingCard plan={plan} key={plan.name} horizontal />)}
        {downgradePlans.map(plan => <PricingCard plan={plan} key={plan.name} horizontal />)}
      </div>
      <div className="subscription-actions">
        <Button
          icon={X}
          loading={isLoading}
          label="Cancel subscription"
          onClick={() => setShowCancelAlert(true)}
          />
      </div>
    </div>
  );

}