import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertModal } from '../../components/modals/AlertModal';
import Textbox from '../../components/textbox/Textbox';
import Select from '../../components/select/Select';
import { useAuth } from '../../context/AuthContext';
import { PackageSchema } from '../../utils/types';

import "./CreatePackageModal.scss";

interface CreatePackageModalProps {
  onClose: () => void;
  createPackage: (name: string, environment: string) => Promise<any>;
}

export function CreatePackageModal({ onClose, createPackage }: CreatePackageModalProps) {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { organization } = useAuth();
  const navigate = useNavigate();

  const handleCreate = async (complete: Function) => {
    if (!name.trim()) {
      setError('Package name is required');
      complete();
      return;
    }
    setIsLoading(true);
    try {
      const fullName = `@${organization.name}/${name.trim()}`;
      const environment = 'development';
      const pkg = await createPackage(fullName, environment);
      navigate(`/code/${pkg.display_name}/${environment}?fullscreen=true`);
    } catch (err: any) {
      setError(err.message || 'Failed to create package');
      complete();
    }
  };

  return (
    <div data-component="CreatePackageModal">
      <AlertModal
        type="children"
        message="Create a new package"
        confirmLabel="Create package"
        color="blue"
        canConfirm={!!name.trim()}
        onClose={onClose}
        onConfirm={handleCreate}
        onCancel={onClose}
      >
        <div className="create-package-title">Package name</div>
        <div className="package-name">
          <div className="package-organization-name">@{organization.name}/</div>
          <Textbox
            autoFocus={true}
            placeholder="my-package"
            value={name}
            onChange={(value) => {
              setName(value);
              setError('');
            }}
            error={error}
          />
        </div>
        <div className="create-package-title">Package type</div>
        <Select
          value="public"
          options={[{ value: 'public', label: 'Public' }]}
          readonly
        />
      </AlertModal>
    </div>
  );
} 