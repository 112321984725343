import { useLoaderData, useSearchParams } from "react-router-dom";
import { AgentsCode } from "../dashboard/agents-chat/code/AgentsCode";
import "./PackageCodePage.scss";
import { PackageDetailsPageLoaderData } from "./PackageDetailsPageLoader";
import { useCallback, useEffect, useState } from "react";
import { Breadcrumb, useBreadcrumbs } from "../../context/BreadcrumbsContext";
import { Code, Key, Lock, Package, User } from "react-feather";
import { Helmet } from "react-helmet-async";
import { AgentsKeychain } from "../dashboard/agents-chat/keychain/AgentsKeychain";
import { PackageSchema } from "../../utils/types";

export default function PackageCodePage() {

  const {
    pkg,
    version,
    environment
  } = useLoaderData() as PackageDetailsPageLoaderData;

  const [ searchParams ] = useSearchParams();
  const tab = {
    'keychain': 'keychain',
    'code': 'code'
  }[searchParams.get('tab') || ''] || 'code';

  const [ displayPkg, setDisplayPkg ] = useState<PackageSchema>(pkg);

  const { updateBreadcrumbs } = useBreadcrumbs();
  let title = `${pkg.display_name}/${version || environment}`;
  const description = pkg?.description || 'Edit code for this package on Funct';
  let displayTitle = pkg.display_name.split('/').slice(-1).join('/');
  let packageTitle = displayTitle;
  let rootPath = `/packages/${pkg.display_name}`;
  const currentPath = window.location.pathname;

  const onPackageUpdated = useCallback((pkg: PackageSchema) => {
    setDisplayPkg(pkg);
  }, [pkg]);

  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [
      {
        icon: User,
        label: `@${pkg.organization.name}`,
        path: `/packages/@${pkg.organization.name}`
      },
      {
        icon: pkg.display_name.startsWith('@') ? Package : Lock,
        label: packageTitle,
        path: rootPath
      },
      {
        icon: pkg.display_name.startsWith('@') ? Package : Lock,
        label: `${packageTitle} (${version || environment})`,
        path: `${rootPath}/${version || environment}`
      }
    ];
    if (pkg.apiKeychain) {
      breadcrumbs.push({
        icon: Key,
        color: 'orange',
        label: 'Test keychain',
        path: currentPath + '?tab=keychain&fullscreen=t',
        stopCollapse: true
      });
    }
    breadcrumbs.push({
      icon: Code,
      color: 'blue',
      label: 'Package editor',
      path: currentPath + '?fullscreen=t'
    });
    updateBreadcrumbs(breadcrumbs);
    return () => {
      updateBreadcrumbs([]);
    };
  }, [rootPath, packageTitle, displayTitle, pkg]);

  return (
    <div data-component="PackageCodePage">
      <Helmet>
        <title>{title} | Funct</title>
        <meta name="description" content={description} />
      </Helmet>
      {tab === 'keychain' && <AgentsKeychain apiKeychain={pkg.apiKeychain} />}
      {tab === 'code' && (
        <AgentsCode
          pkg={displayPkg}
          environment={environment}
          version={version}
          onPackageUpdated={onPackageUpdated}
        />
      )}
    </div>
  );

};